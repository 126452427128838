import React from 'react'
import axios from 'axios'
import _ from 'lodash'
import styles from './adyenCheckoutFormStyles'
import injectSheet from 'react-jss'
import { getEndpoint } from '../../network/network'
import {
  ADYEN_ORIGIN_KEY,
  ADYEN_ENVIRONMENT,
  APPLE_MERCHANT_ID,
  GOOGLEPAY_MERCHANT_IDENTIFIER,
} from '../../../config/config'

const fixMethodName = method => {
  let newName = 'CARD'
  switch (method) {
    case 'applepay':
      newName = 'APPLE_PAY'
      break
    case 'paywithgoogle':
      newName = 'GOOGLE_PAY'
      break
  }

  return newName
}
class AdyenCheckoutForm extends React.Component {
  constructor(props) {
    super(props)
    this.dropinRef = React.createRef()
    this.threedsref = React.createRef()

    this.adyenDidLoad = React.createRef()

    this.loadAdyen = this.loadAdyen.bind(this)
    this.makePayment = this.makePayment.bind(this)
    this.sendAdditonalData = this.sendAdditonalData.bind(this)
    this.show3DSPopover = this.show3DSPopover.bind(this)
    this.endpoint = getEndpoint()
    this.getAdyenCheckout = this.getAdyenCheckout.bind(this)
    this.submit = this.submit.bind(this)
    this.state = {
      popoverOpen: false,
      currentComponent: 'card',
    }

    this.didSubmit = React.createRef()
  }

  makePayment(state) {
    this.props.isLoading(true)
    const {
      paymentData: { shortId: journeyShortId },
    } = this.props
    const transactionType = fixMethodName(this.state.currentComponent)
    return axios.post(
      `${this.endpoint}adyen/payment`,
      {
        journeyShortId,
        state,
        transactionType,
      },
      { withCredentials: true }
    )
  }

  sendAdditonalData(state) {
    this.props.isLoading(true)
    const {
      paymentData: { shortId: journeyShortId },
    } = this.props
    const transactionType = fixMethodName(this.state.currentComponent)
    return axios.post(
      `${this.endpoint}adyen/payment/additional-details`,
      {
        state,
        journeyShortId,
        transactionType,
      },
      { withCredentials: true }
    )
  }

  runSuccess() {
    dropin
      .setStatus('success', {
        message: 'Payment successful!',
      })
      .delay(() => {
        this.props.onSuccess()
      }, 200)
  }

  submit(state, dropin) {
    this.makePayment(state)
      .then(response => {
        this.didSubmit.current = false
        this.props.isLoading(false)
        const statusCode = _.get(response, 'data.statusCode', null)
        const refused = _.get(response, 'data.refusalReason', null)

        if (statusCode === 500 || refused) {
          const errorMsg =
            _.get(response, 'data.error.message', null) || refused
          this.props.onError({
            message: errorMsg,
          })
          return
        }
        if (response.data.resultCode === 'Authorised') {
          dropin.setStatus('success', {
            message: 'Payment successful!',
          })
          this.props.onSuccess()
        } else {
          dropin.handleAction(response.data.action)
        }
      })
      .catch(error => {
        this.didSubmit.current = false
        this.props.isLoading(false)
        this.props.onError({
          message:
            'There was a problem processing your card. Please try again.',
        })
      })
  }

  loadAdyen(paymentMethodsResponse) {
    const estimatedCost = _.get(
      this,
      'props.paymentData.dispatcherEstimatedTripCost',
      1000
    )
    const fleetName = _.get(this, 'props.paymentData.driver.fleet.name', '')
    const googleENV = ADYEN_ENVIRONMENT === 'test' ? 'TEST' : 'PRODUCTION'
    const configuration = {
      locale: 'en_GB',
      environment: `${ADYEN_ENVIRONMENT}`,
      originKey: `${ADYEN_ORIGIN_KEY}`,
      paymentMethodsResponse,
      amount: {
        value: estimatedCost,
        currency: 'GBP',
      },
    }
    const checkout = new window.AdyenCheckout(configuration)
    const checkoutDropin = checkout.create('dropin', {
      paymentMethodsConfiguration: {
        applepay: {
          // Required configuration for Apple Pay
          configuration: {
            merchantName: 'AirPay Global Limited', // Name to be displayed on the form
            merchantIdentifier: APPLE_MERCHANT_ID, // Your Apple merchant identifier as described in https://developer.apple.com/documentation/apple_pay_on_the_web/applepayrequest/2951611-merchantidentifier,
          },
          countryCode: 'GB',
          currencyCode: 'GBP',
          shippingMethods: [],
          lineItems: [
            {
              label: 'Estimated Fare',
              amount: estimatedCost / 100,
              type: 'final',
            },
          ],
          total: {
            label: fleetName,
            type: 'pending',
            amount: estimatedCost,
          },
          supportedNetworks: ['masterCard', 'visa'],
          merchantCapabilities: ['supports3DS'],
          requiredBillingContactFields: [],
          requiredShippingContactFields: [],
          shippingType: 'servicePickup',
          amount: estimatedCost,
          onValidateMerchant: (resolve, reject, validationURL) => {
            axios
              .post(`${this.endpoint}adyen/validatesession`, {
                validationURL,
              })
              .then(resp => {
                resolve(resp.data)
              })
              .catch(err => {
                reject(err)
              })
          },
          onChange: (state, dropin) => {
            this.submit(state, dropin)
          },
        },
        paywithgoogle: {
          amount: {
            currency: 'GBP',
            value: estimatedCost / 100,
          },
          // Example required configuration for Google Pay
          environment: googleENV, // Change this to PRODUCTION when you're ready to accept live Google Pay payments
          configuration: {
            gatewayMerchantId: 'AirpayMP', // Your Adyen merchant or company account name
            merchantIdentifier: GOOGLEPAY_MERCHANT_IDENTIFIER, // Required for PRODUCTION. Remove this object in TEST. Your Google Merchant ID as described in https://developers.google.com/pay/api/web/guides/test-and-deploy/deploy-production-environment#obtain-your-merchantID
            merchantName: 'AirPay Global Limited', // Optional. The name that appears in the payment sheet.
          },
        },
        card: {
          amount: { value: 0, currency: 'GBP' },
          // Example optional configuration for Cards
          hasHolderName: false,
          holderNameRequired: false,
          enableStoreDetails: false,
          name: 'Credit or Debit Card',
        },
      },
      onError: err => {
        // Error happened
        this.props.onError(err)
      },
      onReady: e => {
        // Adyen ready
        this.adyenDidLoad.current = true
      },
      onSelect: component => {
        this.setState({
          currentComponent: component.props.type,
        })
      },
      onSubmit: (state, dropin) => {
        if (this.didSubmit.current === true) {
          return
        }
        this.didSubmit.current = true

        if (this.state.currentComponent === 'applepay') {
          return false
        }
        this.submit(state, dropin)
      },
      onAdditionalDetails: (state, dropin) => {
        this.sendAdditonalData(state)
          .then(res => {
            this.props.isLoading(false)
            if (res.data.resultCode === 'Authorised') {
              this.props.onSuccess()
              dropin.setStatus('success', {
                message: 'Payment successful!',
              })
              return
            }
            const { authentication, paymentData } = res.data

            this.show3DSPopover(true)
            checkout
              .create('threeDS2Challenge', {
                challengeToken: authentication['threeds2.challengeToken'],
                onComplete: challengeResult => {
                  // set this back to original payment data since this call resturns null...
                  challengeResult.data.paymentData = paymentData
                  this.sendAdditonalData(challengeResult)
                    .then(res2 => {
                      this.show3DSPopover(false)
                      const thisResultCode = res2.data.resultCode
                      if (thisResultCode === 'Authorised') {
                        this.props.onSuccess()
                        dropin.setStatus('success', {
                          message: 'Payment successful!',
                        })
                      } else {
                        this.props.onError(res2.data)
                        dropin.setStatus('ready')
                      }
                    })
                    .catch(error => {
                      this.show3DSPopover(false)
                      this.props.onError(error)
                      dropin.setStatus('ready')
                    })
                },

                onError: error => {
                  this.show3DSPopover(false)
                  this.props.onError(error)
                  dropin.setStatus('ready')
                }, // Gets triggered on error.
                size: '05', // Defaults to '01'
              })
              .mount(this.threedsref.current)
          })
          .catch(error => {
            this.props.isLoading(false)
            this.props.onError(error)
          })
      },
    })

    const loadInterval = setInterval(() => {
      if (this.adyenDidLoad.current === false) {
        checkoutDropin.unmount()
        try {
          checkoutDropin.mount(this.dropinRef.current)
        } catch (err) {
          window.location.reload()
          clearInterval(loadInterval)
        }
      } else {
        clearInterval(loadInterval)
      }
    }, 5000)

    setTimeout(() => {
      checkoutDropin.mount(this.dropinRef.current)
      this.adyenDidLoad.current = false
    }, 500)
  }

  show3DSPopover(show) {
    this.setState({
      popoverOpen: show,
    })
  }

  getAdyenCheckout() {
    if (!window.AdyenCheckout) {
      setTimeout(() => {
        this.getAdyenCheckout()
      }, 200)
      return
    }
    axios
      .post(
        `${this.endpoint}adyen/auth`,
        { paymentData: this.props.paymentData },
        { withCredentials: true }
      )
      .then(response => {
        // handle success

        if (response.status === 200) {
          this.loadAdyen(response.data)
        }
      })
      .catch(error => {
        // handle error
        this.props.onError(error)
      })
  }

  componentDidMount() {
    this.getAdyenCheckout()
  }

  render() {
    const show = this.state.popoverOpen
    const { popoverBackground, innerWindow } = this.props.classes
    return (
      <div>
        <div id="dropin" ref={this.dropinRef} />

        {show && (
          <div className={popoverBackground}>
            <div className={innerWindow} id="threeDS2" ref={this.threedsref} />
          </div>
        )}
      </div>
    )
  }
}

export default injectSheet(styles)(AdyenCheckoutForm)
